import http from './httpRequest.js'

const GameApi = {}

GameApi.requestProxy = function (url) {
    return http({
        url: `/proxyHttp?url=`+url,
        method: 'get'
    })
}

GameApi.requestGame = function () {
    return GameApi.requestProxy('api/v1/system/get_game');
}

GameApi.requestWingoList = function () {
    return GameApi.requestProxy('api/v1/project/list');
}

GameApi.requestDiceList = function () {
    return GameApi.requestProxy('api/v1/projectK3/list');
}

GameApi.requestTrxWingoList = function () {
    return GameApi.requestProxy('api/v1/projectHash/list');
}

GameApi.requestProjectD5List = function () {
    return GameApi.requestProxy('api/v1/projectD5/list');
}

export default GameApi;
