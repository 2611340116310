<template>
  <el-drawer title="游戏列表" size="700px" :visible.sync="visible" direction="rtl" :before-close="onClose">
    <div class="chat-history" v-loading="loading"
         element-loading-text="拼命加载中">
      <el-scrollbar class="chat-history-scrollbar" ref="scrollbar" id="historyScrollbar">
        <ul>
          <li v-for="(item,idx) in gameList" :key="idx">
            <chat-game-item
                @send="handleSend"
                :list="item.list"
                :game-id="item.game_id"
                :icon="item.game_img"
                :name="item.game_name"></chat-game-item>
          </li>
        </ul>
      </el-scrollbar>
    </div>
  </el-drawer>
</template>

<script>
import ChatMessageItem from './ChatMessageItem.vue';
import GameApi from "@/api/GameApi";
import ChatGameItem from "@/components/chat/ChatGameItem.vue";

export default {
  name: 'chatHistory',
  components: {
    ChatGameItem,
    ChatMessageItem
  },
  props: {
    visible: {
      type: Boolean
    },
    chat: {
      type: Object
    },
    friend: {
      type: Object
    },
    group: {
      type: Object
    },
    groupMembers: {
      type: Array,
    }
  },
  data() {
    return {
      gameList: [],
      loading: true,
    }
  },
  methods: {
    handleSend(content){
      console.log('handleSend', content)
      let list = [{
        type:'game',
        content
      }]
      this.$emit('send', list);
    },
    onClose() {
      this.page = 1;
      this.messages = [];
      this.loadAll = false;
      this.$emit('close');
    },

    loadingGames() {
      GameApi.requestGame().then(res => {
        console.log('loadingGames', res)
        this.gameList = res[0].games;
        this.loading = false;

        GameApi.requestWingoList().then(res => {
          let game = this.gameList.find(g => g.game_id == '1000');
          console.log('requestWingoList ', res)
          if (game) game.list = res.list;
          this.$forceUpdate();
        })

        GameApi.requestTrxWingoList().then(res => {
          let game = this.gameList.find(g => g.game_id == '1003');
          console.log('requestWingoList ', res)
          if (game) game.list = res.list;
          this.$forceUpdate();
        })

        GameApi.requestDiceList().then(res => {
          let game = this.gameList.find(g => g.game_id == '1001');
          console.log('requestWingoList ', res)
          if (game) game.list = res.list;
          this.$forceUpdate();
        })

        GameApi.requestProjectD5List().then(res => {
          let game = this.gameList.find(g => g.game_id == '1002');
          console.log('requestWingoList ', res)
          if (game) game.list = res.list;

          this.$forceUpdate();
        })
      })
    },

    loadMessages() {
      if (this.loadAll) {
        return this.$message.success("已到达顶部");
      }
      let param = {
        page: this.page++,
        size: this.size
      }
      if (this.chat.type == 'GROUP') {
        param.groupId = this.group.id;
      } else {
        param.friendId = this.friend.id;
      }
      this.loading = true;
      this.$http({
        url: this.histroyAction,
        method: 'get',
        params: param
      }).then(messages => {
        messages.forEach(m => this.messages.unshift(m));
        this.loading = false;
        if (messages.length < this.size) {
          this.loadAll = true;
        }
        this.refreshScrollPos();
      }).catch(() => {
        this.loading = false;
      })
    },
    showName(msgInfo) {
      if (this.chat.type == 'GROUP') {
        let member = this.groupMembers.find((m) => m.userId == msgInfo.sendId);
        return member ? member.showNickName : "";
      } else {
        return msgInfo.sendId == this.mine.id ? this.mine.nickName : this.chat.showName
      }
    },
    headImage(msgInfo) {
      if (this.chat.type == 'GROUP') {
        let member = this.groupMembers.find((m) => m.userId == msgInfo.sendId);
        return member ? member.headImage : "";
      } else {
        return msgInfo.sendId == this.mine.id ? this.mine.headImageThumb : this.chat.headImage
      }
    },
    refreshScrollPos() {
      let scrollWrap = this.$refs.scrollbar.$refs.wrap;
      let scrollHeight = scrollWrap.scrollHeight;
      let scrollTop = scrollWrap.scrollTop;
      this.$nextTick(() => {
        let offsetTop = scrollWrap.scrollHeight - scrollHeight;
        scrollWrap.scrollTop = scrollTop + offsetTop;
        // 滚动条没出来，继续加载
        if (scrollWrap.scrollHeight == scrollHeight) {
          this.loadMessages();
        }
      });
    }
  },
  computed: {
    mine() {
      return this.$store.state.userStore.userInfo;
    },
    histroyAction() {
      return `/message/${this.chat.type.toLowerCase()}/history`;
    }
  },
  watch: {
    visible: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.loadingGames();
        }
      }
    }
  }
}
</script>

<style lang="scss">
.chat-history {
  display: flex;
  height: 100%;

  .chat-history-scrollbar {
    flex: 1;

    .el-scrollbar__thumb {
      background-color: #555555;
    }

    ul {
      padding: 20px;

      li {
        list-style-type: none;
      }
    }
  }
}
</style>
