<template>
	<div class="flex flex-col">
		<div>{{name}}</div>

    <div class="chat-game-content" style="width: 100%;">
      <div v-for="item in list"
           @click.stop="handleClick(item)"
           style="background: #eeeeee"
           :key="item.id" class="flex items-center mr-2 game-btn">
        {{item.project_name}}
      </div>
    </div>
	</div>
</template>

<script>
	import HeadImage from "../common/HeadImage.vue";
	import RightMenu from '../common/RightMenu.vue';
	import ChatGroupReaded from './ChatGroupReaded.vue';
	export default {
		name: "ChatGameItem",
		components: {
		},
		props: {
      name:{
        type: String,
        defalut: ''
      },
      gameId:{
        type: String,
        defalut: ''
      },
      icon:{
        type: String,
        defalut: ''
      },
      gameCode:{
        type: String,
        defalut: ''
      },
      list:{
        type: Array,
        defalut: []
      }
		},
		data() {
			return {
			}
		},
		methods: {
      handleClick(item){
        this.$emit('send',JSON.stringify({
          type: 'game',
          name: this.name,
          gameId: this.gameId,
          icon: this.icon,
          gameCode: item.project_code
        }))
      }
		},
		computed: {

		}
	}
</script>

<style lang="scss">
.chat-game-content {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.game-btn {
  background: orange;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
</style>
